import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

export const subtotalStore = defineStore('subtotal', () => {
  const loading = ref(false)
  const subtotals = ref({
    building: '0',
    carcass: '0',
    completion1: '0',
    completion2: '0',
    completion3: '0',
    extra: '0',
    modular: '0',
    total: '0',
    total_options: '0'
  })

  function getSubtotals () {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/building/subtotals').then((r) => {
      subtotals.value = r.data
    }).finally(() => {
      loading.value = false
    })
  }

  return { loading, subtotals, getSubtotals }
})
